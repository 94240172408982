import React from "react";
import { graphql, navigate } from "gatsby";
import styled from "styled-components";
import Layout from "./../components/Layout";
import { ImagePageLayout } from "./../components/StandalonePageLayout";
import { H4BigStrong, H5Strong, H5 } from "./../components/Typography";
import { SmallButton, BigButton } from "./../components/Buttons";
import ArrowLeft from "./../components/icons/ArrowRightLine";
import Download from "./../components/icons/Download";
import { cleanCraftDate, getDateDay } from "./../modules/time";

const JobInfoTable = styled.div`
  display: flex;
  margin: 25px 0;
  & > div:first-of-type {
    margin-right: 25px;
  }
`;

const JobDescriptionDiv = styled.div`
  & p {
    margin-bottom: 18px;
  }
`;

//TODO: Need real icons
//TODO: Need to know what "job information pack is"
//TODO: Need to know what "apply" button does

const JobPage = ({ data, pageContext }) => {
  // console.log(data, pageContext);
  const {
    title,
    headerImage,
    jobClosingDate,
    jobId,
    jobLevel,
    jobLocation,
    richTextField,
    applyLink,
    venuePdfForDownload,
    callToAction,
  } = data.jobData;
  const newDate = new Date(
    Date.parse(cleanCraftDate(jobClosingDate)) + 1000 * 60 * 60 * 24
  );
  const theDate = getDateDay(newDate);
  return (
    <Layout
      backgroundColor={"var(--purple)"}
      footerColor={"var(--purple)"}
      title={title}
    >
      <ImagePageLayout
        fixedImage
        gatsbyImageImage={
          headerImage[0] && headerImage[0].wideImage
            ? headerImage[0].wideImage.childImageSharp.fixed
            : null
        }
        topAdjustment={"30px"}
      >
        <h1>{data.workWithUsMainPage.title}</h1>
        <div>
          <div>
            <H4BigStrong>{title}</H4BigStrong>
            <JobInfoTable>
              <div>
                <H5Strong>Closing date</H5Strong>
                <H5Strong>Job ID</H5Strong>
                <H5Strong>Level</H5Strong>
                <H5Strong>Location</H5Strong>
              </div>
              <div>
                <H5>{theDate}</H5>
                <H5>{jobId}</H5>
                <H5>{jobLevel}</H5>
                <H5>{jobLocation}</H5>
              </div>
            </JobInfoTable>
            <JobDescriptionDiv
              dangerouslySetInnerHTML={{ __html: richTextField }}
            />
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "50px",
              }}
            >
              <a href={"mailto:talent@alserkal.online" || applyLink}>
                <BigButton color={"var(--black)"}>
                  {callToAction || "APPLY"}
                </BigButton>
              </a>
            </p>
            <p
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginTop: "50px",
              }}
            >
              <SmallButton
                color={"var(--purple)"}
                onClick={() => {
                  navigate(`/work-with-us/`);
                }}
              >
                <ArrowLeft
                  color={"var(--purple)"}
                  flip
                  style={{
                    width: "18px",
                    height: "18px",
                    marginRight: "8px",
                  }}
                />
                Back to overview
              </SmallButton>
              {venuePdfForDownload.length ? (
                <a href={venuePdfForDownload[0].url} download>
                  <SmallButton color={"var(--purple)"}>
                    <Download color={"var(--purple)"} />
                    Download job information pack
                  </SmallButton>
                </a>
              ) : null}
            </p>
          </div>
        </div>
      </ImagePageLayout>
    </Layout>
  );
};

export default JobPage;

export const pageQuery = graphql`
  query($id: String!) {
    jobData: craftEntryInterface(id: { eq: $id }) {
      ... on Craft_jobs_job_Entry {
        title
        richTextField
        jobLocation
        jobLevel
        jobClosingDate
        jobId
        applyLink
        callToAction
        venuePdfForDownload {
          url
        }

        headerImage {
          ... on Craft_editorialAssets_Asset {
            wideImage: localFile {
              publicURL
              childImageSharp {
                fixed(width: 1440) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
    workWithUsMainPage: craftWorkWithUsWorkWithUsEntry {
      title
    }
  }
`;
